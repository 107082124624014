<template>
    <div class="about">
        <div class="banner">
            <img :src="banner.picture" alt="关于我们">
            <div class="banner_cont">
                <h3>{{banner.title}}</h3>
                <div class="page_nav" v-if="langs == 'zh_cn'">
                    <router-link to="/">首页</router-link> > <span>关于我们</span>
                </div>
                <div class="page_nav" v-else>
                    <router-link to="/">Home</router-link> > <span>AboutUs</span>
                </div>
            </div>
        </div>
        <div class="about_plate1">
            <dir class="layout">
                <div class="about_plate1_pack">
                    <div class="about_plate1_img img">
                        <img :src="about_plate1.picture" :alt="about_plate1.title">    
                    </div> 
                    <div class="about_plate1_cont">
                        <h4>{{about_plate1.title}}</h4>
                        <div class="about_plate1_desc ck-content" v-html="about_plate1.detailcontent">

                        </div>
                    </div>
                </div>
                <div class="jiagou">
                    <div class="public_title" v-if="langs == 'zh_cn'">
                        <h3>组织架构</h3>
                    </div>
                    <div class="public_title" v-else>
                        <h3>Organizational structure</h3>
                    </div>
                    <div class="jiagou_img ">
                        <img :src="about_plate1.f01" alt="">    
                    </div> 
                </div>
            </dir>
        </div>
        <div class="about_plate2">
            <div class="layout">
                <div class="public_title" v-if="langs == 'zh_cn'">
                    <h3>专家智库</h3>
                    <p>Expert</p>
                </div>
                <div class="public_title" v-else>
                    <h3>Expert think tank</h3>
                    <!-- <p>Expert</p> -->
                </div>
                <ul class="expert_list">
                    <li v-for="item in expert" :key="item.id">
                        <div class="expert_list_pack">
                            <div class="expert_list_img img">
                                <img :src="item.picture" :alt="item.title">
                            </div>
                            <div class="expert_list_cont">
                                <h4 class="yih">{{item.title}}</h4>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="expert_btn" v-if="langs == 'zh_cn'">
                    <router-link to="/Expert">查看更多</router-link>
                </div>
                <div class="expert_btn" v-else>
                    <router-link to="/Expert">View more</router-link>
                </div>
            </div>
        </div>
        <div class="about_plate3">
            <dir class="layout">
                <div class="public_title" v-if="langs == 'zh_cn'">
                    <h3>企业展示</h3>
                    <p>Enterprise</p>
                </div>
                <div class="public_title" v-else>
                    <h3>Enterprise Display</h3>
                    <!-- <p>Enterprise</p> -->
                </div>
                <div class="about_plate3_pack">
                    <div class="about_plate3_left">
                        <div class="about_plate1_icon img">
                            <img src="../../public/images/dw.png" alt="会员单位">
                        </div>
                        <p v-if="langs == 'zh_cn'">会员单位</p>
                        <p v-else>Member units</p>
                    </div>
                    <div class="enterprise_list">
                        <div class=" swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in enterprise" :key="item.id">
                                    <a :href="item.description" target="_blank">
                                        <div class="enterprise_list_img img">
                                            <img :src="item.picture" :alt="item.title">  
                                        </div> 
                                        <p>{{item.title}}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dir>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import "swiper/dist/css/swiper.css"
export default {
    components:{
        Swiper
    },
     data(){
        return {
            banner:{},
            about_plate1:{},
            expert:{},
            enterprise:{},
            langs:'zh_cn'
        }
    },
    methods: {
        getData(parameteradd,tc,lang){
            return this.$axios.get(parameteradd,{
                        params:{
                            typecode:tc,
                            language:lang
                        }
                    })
        },
        getListData(parameteradd,tc,page,pagenum,ids,l){
            return this.$axios.get(parameteradd,{
                        params:{
                            page:page,
                            limit:pagenum,
                            typecode:tc,
                            categoryid:ids,
                            language:l
                        }
                    })
        }
    },
    
    mounted() {
        const _this = this
        if(_this.$Cookie.get('language') == "" || _this.$Cookie.get('language') == null || _this.$Cookie.get('language') == "undefined"){
            _this.langs = "zh_cn"
        }else{
             _this.langs = _this.$Cookie.get('language')
        }
        
        _this.getData('/Article/GetArticleDetailByTc','aboutbanner',_this.langs).then((res)=>{
            _this.banner = res.data.data;
            _this.banner.picture = _this.url+res.data.data.picture
        })
        _this.getData('/Article/GetArticleDetailByTc','AboutUs',_this.langs).then((res)=>{
            _this.about_plate1 = res.data.data;
            _this.about_plate1.picture = _this.url + res.data.data.picture;
            _this.about_plate1.f01 = _this.url + res.data.data.f01
        })
        _this.getListData('/Article/GetArticleList','Expert',1,5,"",_this.langs).then((res)=>{
            _this.expert = res.data.data
            _this.expert.forEach((k,i) => {
                _this.expert[i].picture = _this.url + k.picture
            });
        })
        _this.getListData('/Article/GetArticleList','enterprise',1,100,"",_this.langs).then((res)=>{
            _this.enterprise = res.data.data
            _this.enterprise.forEach((k,i) => {
                _this.enterprise[i].picture = _this.url + k.picture
            });
            new Swiper (' .swiper-container', {
                observer: true,
                observeParents: true,
                slidesPerView: 5,
                spaceBetween: 20,
                loop:true,
                speed:1000,
                autoplay:2000,
                breakpoints: { 
                    //当宽度小于等于320
                    500: {
                        slidesPerView: 1.2,
                        spaceBetween: 10
                    },
                    //当宽度小于等于480
                    700: { 
                        slidesPerView: 3,
                        spaceBetween:10
                    },
                    //当宽度小于等于640
                    1300: {
                        slidesPerView: 5,
                        spaceBetween: 30
                    }
                }
            }) 
        })
        //进入页面前当前页面
        _this.$router.afterEach((to, from) => {
            if(to.path != from.path) {
                _this.$nextTick(()=>{
                    location.reload()
                })
            }
        })
        
    },
}
</script>

<style>

</style>
