import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import Axios from '@/axios'
import Share  from 'vue-social-share' 
import Cookie from 'js-cookie'
import {zhTran} from '@/langs/lang'
Vue.use(Share)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$axios = Axios;
Vue.prototype.url = 'https://api.nhssco.org';
Vue.prototype.$Cookie = Cookie;
Vue.prototype.$zhTran = zhTran;


new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
