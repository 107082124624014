<template>
    <div class="home">
        <div class="layout">
            <div class="home_pack">
                <div class="home_plate1_pack">
                    <div class="home_plate1_left " >
                        <div class=" swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in homeBanner" :key="item.id">
                                    <div class="home_plate1_img img" @click="bannerdetail(item.id)">
                                        <img :src="item.picture" :alt="item.title">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper_navpack home_plate1_pag">
                                <div class="arr arr_left bxweb bx-zuo" ></div>
                                <div class="swiper-pagination"></div>
                                <div class="arr arr_right bxweb bx-gengduo" ></div>
                            </div>
                        </div>
                    </div>
                    <div class="home_plate1_right ">
                        <ul class="home_plate1_classifylist">
                            <li v-for="(item,index) in homeNewsClassifyList" :key="item.id" @click="tab(item.id,index)" :class="index == activeIndex?'active': ''">
                                <p>{{item.name}}</p>
                            </li>
                        </ul>
                        <ul class="home_plate1_list">
                            <li v-for="item in homeNewsList" :key="item.id">
                                <router-link :to="newsUrl+item.id">
                                    <p class="yih">{{item.title}}</p>
                                    <span>{{item.addtime.split(' ')[0].split('-')[1]+ '-' + item.addtime.split(' ')[0].split('-')[2]}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="home_plate2">
                    <div class="home_public_title " v-if="langs == 'zh_cn'">
                        <h3>协会故事</h3>
                    </div>
                    <div class="home_public_title " v-else>
                        <h3>Association Story</h3>
                    </div>
                    <div class="home_plate2_cont ">
                        <a :href="story.s01">
                            <div class="home_plate2_img img">
                                <img :src="story.picture" :alt="story.title">
                            </div>
                            <div class="home_plate2_text">
                                <p>{{story.title}}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="home_plate3">
                    <div class="home_public_title " v-if="langs == 'zh_cn'">
                        <h3>专题</h3>
                    </div>
                    <div class="home_public_title " v-else>
                        <h3>special</h3>
                    </div>
                    <div class="home_plate3_pack">
                        <div class="home_plate3_left ">
                            <ul class="home_plate3_list">
                                <li v-for="item in special" :key='item.id'>
                                    <div class="home_plate3_list_pack" @click="specialCont(item.id)">
                                        <div class="home_plate3_img img">
                                            <img :src="item.picture" :alt="item.title">
                                        </div>
                                        <div class="home_plate3_title">
                                            <p class="yih">{{item.title}}</p>
                                        </div>
                                    </div>
                                </li>    
                            </ul>    
                        </div>
                        <div class="home_plate4_right ">
                            <div class=" swiper-container">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="item in special2" :key="item.id" @click="specialCont(item.id)">
                                        <div class="home_plate1_img home_plate3_right_img img">
                                            <img :src="item.picture" :alt="item.title">
                                            <div class="home_plate3_title">
                                                <p class="yih">{{item.title}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper_navpack home_plate3_pag">
                                    <div class="arr arr_left bxweb bx-zuo" ></div>
                                    <div class="swiper-pagination"></div>
                                    <div class="arr arr_right bxweb bx-gengduo" ></div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="home_plate4" >
                    <div class="home_plate4_list " v-for="item in homeindustry" :key="item.id" :class="activeClass == 2 ? 'active' : '' ">
                        <h3>{{item.name}}</h3>
                        <ul class="home_plate1_list home_plate4_childern_list">
                            <li v-for="items in item.childlist" :key="items.id">
                                <router-link :to="publicDetailUrl+items.id">
                                    <p class="yih">{{items.title}}</p>
                                    <span>{{items.addtime.split(' ')[0].split('-')[1]+"-" + items.addtime.split(' ')[0].split('-')[2]}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="home_plate5">
                    <div class="home_public_title " v-if="langs == 'zh_cn'">
                        <h3>会员单位展示</h3>
                    </div>
                    <div class="home_public_title " v-else>
                        <h3>Member unit display</h3>
                    </div>
                    <div class="home_plate5_list ">
                        <ul>
                            <li v-for="item in Member_units" :key="item.id">
                                <a :href="item.id">
                                    <div class="home_plate5_list_img img">
                                        <img :src="item.picture" :alt="item.title">
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="home_plate6">
                    <div class="home_plate6_pack">
                        <div class="home_plate6_left ">
                            <div class="home_public_title" v-if="langs == 'zh_cn'">
                                <h3>关于我们</h3>
                            </div>
                            <div class="home_public_title" v-else>
                                <h3>AboutUs</h3>
                            </div>
                            <div class="home_plate6_left_pack">
                                <div class="home_plate6_left_img img">
                                    <img :src="aboutContent.picture" :alt="aboutContent.title">
                                </div>
                                <div class="home_plate6_right_cont">
                                    <div class="home_plate6_right_desc ck-content" v-html="aboutContent.detailcontent">
                                    </div>
                                    <div class="home_plate6_right_btn" v-if="langs == 'zh_cn'">
                                        <router-link to="/AboutUs">协会简介</router-link>
                                        <router-link to="/AboutUs">协会简介</router-link>
                                    </div>
                                    <div class="home_plate6_right_btn" v-else>
                                        <router-link to="/AboutUs">Introduction</router-link>
                                        <router-link to="/AboutUs">Introduction</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="home_plate6_right " v-if="langs == 'zh_cn'">
                            <h3>联系我们</h3>
                            <div class="home_plate6_from">
                                <div class="home_plate6_input">
                                    <input type="text" v-model="messName" placeholder="请输入姓名">    
                                </div>  
                                <div class="home_plate6_input">
                                    <input type="text" v-model="messTel" placeholder="请输入手机号">    
                                </div> 
                                <div class="home_plate6_input">
                                    <textarea placeholder="请输入留言内容" v-model="message"></textarea>    
                                </div>   
                            </div> 
                            <div class="home_plate6_right_submit" @click="messSubmit">提交</div> 
                        </div>
                        <div class="home_plate6_right " v-else>
                            <h3>ContactUs</h3>
                            <div class="home_plate6_from">
                                <div class="home_plate6_input">
                                    <input type="text" v-model="messName" placeholder="Please enter your name">    
                                </div>  
                                <div class="home_plate6_input">
                                    <input type="text" v-model="messTel" placeholder="Please enter your phone number">    
                                </div> 
                                <div class="home_plate6_input">
                                    <textarea placeholder="Please enter the message content" v-model="message"></textarea>    
                                </div>   
                            </div> 
                            <div class="home_plate6_right_submit" @click="messSubmit">Submit</div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home_links ">
            <div class="layout">
                <ul class="home_linkslist">
                    <li>
                        <a href="javascript:;" v-if="langs == 'zh_cn'">
                            友情链接
                        </a>
                        <a href="javascript:;" v-else>
                            Friendship link
                        </a>
                    </li>
                    <li v-for="item in links" :key="item.id">
                        <a :href="item.linkurl">
                            {{item.title}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import "swiper/dist/css/swiper.css"
    export default ({
        components:{
            Swiper
        },
        data(){
            return{
                homeBanner:{},
                homeNewsClassifyList:{},
                homeNewsList:{},
                newsUrl:'/NewsDetail?listnum=',
                publicDetailUrl:'/publicDetail?listnum=',
                activeIndex:0,
                story:{},
                special:{},
                special2:{},
                homeindustry:{},
                activeClass:0,
                Member_units:{},
                messName:'',
                messTel:'',
                message:'',
                aboutContent:{},
                links:{},
                langs:'zh_cn'
            }
        },
        methods:{
            getData(parameteradd,tc,lang){
                return this.$axios.get(parameteradd,{
                            params:{
                                typecode:tc,
                                language:lang
                            }
                        })
            },
            getListData(parameteradd,tc,page,pagenum,ids,lang){
                return this.$axios.get(parameteradd,{
                            params:{
                                page:page,
                                limit:pagenum,
                                typecode:tc,
                                categoryid:ids,
                                language:lang
                            }
                        })
            },
            tab(e,i){
                this.activeIndex = i;
                this.getListData('/Article/GetArticleList','News',1,7,e,this.langs).then((res)=>{
                    this.homeNewsList = res.data.data
                })
            },
            specialCont(e){
                this.$router.push({
                    path:'publicDetail?listnum='+e
                })
            },
            messSubmit(){
                if(this.messName == "") {
                    if(this.langs == 'zh_cn') {
                        alert('请输入姓名')
                    }else {
                        alert('Please enter your name')
                    }
                    return false
                }
                if(this.messTel == "") {
                    if(this.langs == 'zh_cn') {
                        alert('请输入联系电话')
                    }else {
                        alert('Please enter your contact phone number')
                    }
                    return false
                }
                if(this.message == "") {
                    if(this.langs == 'zh_cn') {
                        alert('请输入留言内容')
                    }else {
                        alert('Please enter the message content')
                    }
                    return false
                }
                 var fromData = new FormData()
                    fromData.append('tc',"ContactUs")
                    fromData.append('Name',this.messName)
                    fromData.append('Telephone',this.messTel)
                    fromData.append('MessageContent',this.message)
                    // fromData.append('language',this.language)
                    this.$axios.post('/Message/AddMessage',fromData).then((res)=>{  
                        if(res.data.msg == "成功") {
                            if(this.langs == "zh_cn") {
                                alert('留言成功！')
                            }else {
                                alert('Message successful!')
                            }
                            window.location.reload()
                        }
                    })
            },
            bannerdetail(e){
                this.$router.replace({
                    path:'publicDetail?listnum='+e
                })
            }
        },
        mounted(){
            const _this = this;
             if(_this.$Cookie.get('language') == "" || _this.$Cookie.get('language') == null || _this.$Cookie.get('language') == "undefined"){
            _this.langs = "zh_cn"
        }else{
             _this.langs = _this.$Cookie.get('language')
        }
            //banner
            _this.getListData('/Article/GetArticleList','Banner',1,10,"",_this.langs).then((res)=>{
                _this.homeBanner = res.data.data
                _this.homeBanner.forEach((k,i) => {
                    _this.homeBanner[i].picture = _this.url + k.picture
                });
                new Swiper ('.home_plate1_left .swiper-container', {
                    observer: true,
                    observeParents: true,
                    // loop:true,
                    speed:1000,
                    autoplay:3000,
                    pagination : '.home_plate1_left .swiper-pagination',
                    paginationClickable :true,
                    paginationBulletRender: function (swiper, index, className) {
                        return '<span class="' + className + '">' + (index + 1) + '</span>';
                    },
                    prevButton:'.home_plate1_left .arr_left',
                    nextButton:'.home_plate1_left .arr_right',
                }) 
            })
            //新闻
             _this.getListData('/ArticleCategory/GetArticleCategoryList','News',1,3,"",_this.langs).then((res)=>{
                _this.homeNewsClassifyList = res.data.data
                _this.getListData('/Article/GetArticleList','News',1,7,_this.homeNewsClassifyList[0].id,_this.langs).then((res)=>{
                    _this.homeNewsList = res.data.data
                })
             })
             
             //协会故事
             _this.getData('/Article/GetArticleDetailByTc','story',_this.langs).then(res=>{
                _this.story = res.data.data
                _this.story.picture = _this.url + res.data.data.picture
             })
             //专题
             _this.getListData('/Article/GetArticleList','special',1,3,"",_this.langs).then((res)=>{
                _this.special = res.data.data
                _this.special.forEach((k,i) => {
                    _this.special[i].picture = _this.url + k.picture
                });
             })
             _this.getListData('/Article/GetArticleList','special2',1,3,"",_this.langs).then((res)=>{
                _this.special2 = res.data.data
                _this.special2.forEach((k,i) => {
                    _this.special2[i].picture = _this.url + k.picture
                });
                new Swiper ('.home_plate4_right .swiper-container', {
                    observer: true,
                    observeParents: true,
                    // loop:true,
                    speed:1000,
                    autoplay:3000,
                    pagination : '.home_plate4_right  .swiper-pagination',
                    paginationClickable :true,
                    paginationBulletRender: function (swiper, index, className) {
                        return '<span class="' + className + '">' + (index + 1) + '</span>';
                    },
                    prevButton:'.home_plate4_right .arr_left',
                    nextButton:'.home_plate4_right .arr_right',
                }) 
             })
                //产业展示
             _this.getListData('/ArticleCategory/GetArticleCategoryList','homeindustry',1,100,"",_this.langs).then((res)=>{
                _this.activeClass = res.data.count
                _this.homeindustry = res.data.data
                var childernList = []
                _this.homeindustry.forEach((k,i)=>{
                    _this.getListData('/Article/GetArticleList','homeindustry',1,100,k.id,_this.langs).then((data)=>{
                        childernList.push(data.data.data)
                        childernList.forEach((v,n)=>{
                            k.childlist = v
                        })
                    })
                })
             })
             //单位展示
            _this.getListData('/Article/GetArticleList','Member_units',1,100,"",_this.langs).then((res)=>{
                _this.Member_units = res.data.data
                _this.Member_units.forEach((k,i) => {
                    _this.Member_units[i].picture = _this.url + k.picture
                });
             })
             //关于我们
             _this.getData('/Article/GetArticleDetailByTc','AboutUs',_this.langs).then(res=>{
                _this.aboutContent = res.data.data
                _this.aboutContent.picture = _this.url + res.data.data.picture
             })
             //友情链接
            _this.getListData('/Article/GetArticleList','links2',1,100,"",_this.langs).then((res)=>{
                _this.links = res.data.data
             })
             //进入页面前当前页面
            _this.$router.afterEach((to, from) => {
                if(to.path != from.path) {
                    _this.$nextTick(()=>{
                        location.reload()
                    })
                }
            })
            
        }
         
    })
</script>
